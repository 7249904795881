header {
    position: relative;
    width: 100%;
    display: flex;
    justify-self: center;
    margin: 0 auto;
  }
  
  header .navbar {
    position: fixed;
    width: 80%;
    background-color: #33333320;
    backdrop-filter: blur(3px);
    border-radius: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-top: 2%;
    margin-left: 50%;
    left: 0;
    transform: translateX(-50%);
    padding: 0 1rem;
    text-transform: uppercase;
    z-index: 5;
    
  }
  
  .navbar .logo-marca img { 
    width: 120px;
    cursor: var(--cursor-pointer);
    position: relative;
    top: 4px;
    left: 6px;
  }
  
  .navbar .menu {
    display: flex;
    list-style: none;
    gap: 1rem;
    font-size: 21px;
    color: var(--cor-secundaria);
  }

  .navbar .menu li a {
    text-decoration: none;
    color: var(--cor-secundaria);
    transition: .4s
  }
  
  .navbar .menu li a:hover {
    color: var(--cor-primaria);
    cursor: var(--cursor-pointer);
  }
  
  .navbar a { text-decoration: none }
  .navbar button {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 3rem;
    border-radius: 2rem;
    color: white;
    background: linear-gradient(to right, #cbbacc, #2580b3);
    text-transform: uppercase;
    font-style: italic;
  }
  .navbar button:hover { background: linear-gradient(to right, #2580b3, #cbbacc) }
  
  .menu-mobile {
    height: max-content;
    position: relative;
    display: none;
    list-style: none;
    text-align: left;
  }
  .menu-mobile li {
    position: relative;
    width: 100%;
    padding: 1rem;
    left: -3rem;
  }

  .menu-mobile li a {
    color: white;
    padding: 1rem;
    padding-left: 0;
  }
  
  .menu-mobile-btn { 
    display: none;
    position: fixed;
    bottom: -10px;
    right: 0;
    width: 130px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    border-top-left-radius: 4rem;
    border-bottom-right-radius: 0;
    background-color: #33333390;
    backdrop-filter: blur(3px);
    letter-spacing: 3px;
    z-index: 1000 !important;
  }


  @media (max-width: 900px) {
    .menu { display: none !important;}
    .navbar { padding: 2% !important;}
    .menu-mobile-btn { display: block }
    .menu-mobile { display: none }
    header .navbar {
      margin-left: 50%;
      transform: translateX(-50%);
    }

    .menu-mobile li a:hover {
      color:#bce6ff
    }
  }
  