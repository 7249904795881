section.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

section.footer > h2 {
    font-size: var(--title-size);
    color: var(--cor-secundaria);
    font-style: italic;
    margin: 0;
}
section.footer > img {
    width: 50%;
    padding: 1rem;
}

section.footer > div {
    display: flex;
    gap: 1rem;
    transform: scale(.8);
}

section.footer h3, section.footer h4 {
    font-style: italic;

}