main section.hero {
    position: relative;
    width: 100%;
    margin-top: 3rem;
    display: flex;
    z-index: 3;
}

section.hero img {
    position: relative;
    left: -5%;
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
}

section.hero > div {
    position: absolute;
    bottom: 100px;
    display: flex;
    gap: 200px;
    left: 50%;
    transform: translateX(-50%);
}

section.hero button a { text-decoration: none; color: white }
section.hero button {
    background: #666666d7;
    font-size: 1rem;
    font-weight: bold;
    padding: 0 2rem;
    letter-spacing: 2px;
}
section.hero button:hover { background: linear-gradient(to left, #2580b3, #cbbacc) }
section.hero button.actived { background: linear-gradient(to left, #2580b3, #cbbacc) }


@media (max-width: 900px) {
    main section.hero { margin-top: 6rem !important }
    section.hero { margin: 50px 0 }
    section.hero > div {
        bottom: -4rem;
        gap: 1rem;
    }

    section.hero > div button { width: 45vw }
}
