.Faq-container > h2 {
    font-size: var(--title-size);
    color: var(--cor-secundaria);
    text-align: center;
    font-style: italic;
}

.Faq-item {
    position: relative;
    width: 100%;
    background: linear-gradient(to right, #cbbacc85, #257fb36e);
    border-radius: 3rem;
    margin: 2rem auto;
}

.Faq-button img{
    width: 100%;
}

.Faq-item > div:nth-of-type(1) {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 40px 3fr 0.2fr;
    padding: 1rem;
}

.Faq-item > div:nth-of-type(1) .icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #3f9dd472;
    border: 1px solid var(--cor-secundaria)
}
.Faq-item > div:nth-of-type(1) .icon h2 {
    color: white;
    font-size: 1.5rem
}

.Faq-item h2 {
    font-size: 1.2rem;
    color: var(--cor-secundaria);
    text-transform: uppercase;
    text-align: left;
}

.Faq-item button {
    background: unset;
    border: none;
}

.Faq-item > div:nth-of-type(2) {
    padding: 1rem 2rem ;
    margin-top: -2rem;
}

.Faq-item > div:nth-of-type(2) p {
    font-style: italic;
}

.Faq-item {
    overflow: hidden; /* Oculta o conteúdo que ultrapassa a altura definida */
    transition: height 0.3s ease; /* Adiciona uma transição de altura */
    margin: 2rem auto; /* Adiciona um espaçamento entre os itens */
  }
  
  .Faq-item.open {
    height: auto; /* Define a altura como automática para mostrar todo o conteúdo */
  }
  
  .Faq-button img {
    transition: transform 0.3s ease-in-out; /* Adiciona uma transição suave */
  }
  
  .Faq-button.open img {
    transform: rotate(180deg); /* Rotaciona a imagem em 180 graus quando a classe 'open' está presente */
  }

  .icon h2 {
    transition: transform 0.3s ease-in-out; /* Adiciona uma transição suave */
  }
  
  
@media (max-width: 800px) {
    .Faq-item button {
        padding: 0;
    }
}