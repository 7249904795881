section.cards {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
}


section.cards > b { 
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 2% 0;
    width: 70%;
    margin: 0 auto;
}

section.cards > h2, section.cards > p { text-align: center } 
section.cards > h2 {
    font-size: var(--title-size);
    color: var(--cor-secundaria);
    text-transform: uppercase;
    font-style: italic;
}

section.cards .card-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    overflow-y: hidden;
    padding-left: 2rem;
    
}

.card-container .card-single {
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #257fb36a, #cbbacc65);
    width: 280px;
    padding: 2rem;
    border-radius: 2rem;
}

.card-container .card-single .title {
    position: relative;
    display: flex;
    position: relative;
    align-items: center;
    left: -60px;
}

.card-container .card-single .circle {
    position: relative;
    width: 100px;
    height: 100px;
    background: var(--cor-secundaria);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3
}

.card-container .card-single .circle img {
    width: 70px;
}

.card-container .card-single .have {
    position: absolute;
    background-color: var(--cor-secundaria);
    color: white;
    width: calc(100% - 100px);
    height: 4rem;
    padding-left: 50px;
    left: 50px;
    border-radius: 2rem;
}

.card-container .card-single .description {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: -2rem;
}

.card-container .card-single .description li {
    display: flex;
    gap: 1rem;
    justify-content: left;
    align-items: center;
    font-style: italic;
}
.card-container .card-single .description li img {
    width: 2rem;
    height: 2rem;
}

.card-container .card-single a {width: 100%;}
.card-container .card-single button {
    width: 100%;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 3px;
}
.card-container .card-single button:hover {background: linear-gradient(to left, #cbbacc, #2580b3)}


@media (max-width: 800px) {
    .card-container .card-single { min-width: 80% }
    section.cards .card-container {
        overflow-x: auto;
        position: relative;
        padding-left: 2rem;
        gap: 2rem;
        justify-content: left
    }

    .card-container { margin-left: 0 !important }
}