#fog {
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("/public/img/fog.png");
    background-size: 200% auto;
    background-position: 0 bottom;
    animation: fogAnim 40s linear infinite;
    margin-left: -2% !important;
    top: -2px;
    right: 0;
    z-index: -10;
    opacity: .6;
}

@keyframes fogAnim {
    from { background-position: -0% bottom }
    to { background-position: -200% bottom }
}

@media (max-width: 800px) {
    #fog {display: none};
}