body, html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Hass;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}

@font-face {
  font-family: Hass;
  src: url('../public/font/fontHass.ttf');
}

::selection {
  background-color: #c497eeab; /* Cor de fundo da seleÃ§Ã£o */
  color: #444; /* Cor do texto da seleÃ§Ã£o */
}

::-webkit-scrollbar-track { border-radius: 1rem; background-color: #e7e7e7 }
::-webkit-scrollbar {
  width: 5px;
  background: #00000000;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, #FF5757, #8C52FF);
	border-radius: 10px;
}

:root {
  --title-size: 32px;
  --title-size-mobile: 1.7rem;
  --cor-primaria: #6eacdc;
  --cor-secundaria: #333333;
  --gradient: linear-gradient(to right, #cbbacc, #2580b3);
  --logo-tipo: url('../public/img/logoTipo.png');
  --logo-marca: url('../public/img/logoMarca.png');
  --cursor-default: url('../public/img/cursorDefault.png') 1 1, pointer !important;
  --cursor-pointer: url('../public/img/cursorPointer.png') 1 1, pointer !important;
}

body {
  cursor: var(--cursor-default) ;
}

a, button { cursor: var(--cursor-pointer) }

b, a { color: var(--cor-primaria) }

h3 { color: var(--cor-secundaria) }

button {
  align-items: center;
  padding: 0 1rem;
  height: 3rem;
  border-radius: 2rem;
  color: white;
  background: linear-gradient(to right, #cbbacc, #2580b3);
  text-transform: uppercase;
  font-style: italic;
}

@media (max-width: 800px) {
  * { cursor: default !important }
}