section.depoiments {
    position: relative;
    width: 90%;
    height: 600px;
    margin: 0 auto;
}

.slick-arrow {
    height: 6rem !important;
}

section.depoiments .bg {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
}

section.depoiments .bg img {
    width: 100%;
    height: 100%;
}

@media (max-width:800px)  {
    section.depoiments .bg {
        position: absolute;
        top: 150px;
        z-index: -1;
        width: 100%;
    }
}