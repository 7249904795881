div.testimonial {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
    padding: 2% 0;
}

div.testimonial img{
    position: absolute;
}

div.testimonial img{
    width: 300px;
    height: 300px;
    border: 8px solid #a3a0dd92;
    border-radius: 50%;
}

div.testimonial h2{
    font-size: var(--title-size);
    color: var(--cor-primaria);
    text-transform: uppercase;
    margin-top: 450px;
    font-style: italic;
}

div.testimonial p{
    color: var(--cor-secundaria);
    margin-top: -1rem;
    font-style: italic;
    text-align: center;
}